<div id="wrapper">
  <div class="vertical-center">
    <img src="assets/logo.svg" class="logo" />
  </div>
</div>

<div class="margin-10">
<mgl-timeline>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#14 Simcoe/El Dorado IPA Sept 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>5.3% IPA
        <ul>
          <li>Pilsener, Münchner, Carahell, Carared, Oats</li>
          <li>Simcoe, El Dorado</li>
          <li>Lallemand VERDANT IPA</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#13 Oatmeal Stout Sept 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>5.1% Stout brewed with Oats
        <ul>
          <li>Pilsener, Oats, Roast Barley, Barley, Caraamber</li>
          <li>Brewers Gold</li>
          <li>LalBrew Nottingham Ale</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#12 Golden Ale August 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>This 5.0% ale could be our first 'signature'. Malty taste, slightly hoppy. Perfect.
        <ul>
          <li>Wiener, Pilsener, Caramünch, Caraamber, Sourmalt</li>
          <li>Hallertauer Tradition, Hallertauer Saphir</li>
          <li>WYEAST 1098 British Ale</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#11 Sauvin-i-oh Pale Ale (Glutenfree) July 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>Next glutenfree attemp. 4.6% pale ale with White Labs Clarity Ferm to make it Glutenfree. As a friend of us, once when we were way younger,
        recommended us a good red-wine -a sauginon blanc - we thought, this was the perfect name for this beer as its hops smell like sauvignon-blanc.
        It ain't redwine and it ain't whitewine either, its a simple (tasteful) beer!
        <ul>
          <li>Pilsener, Wheatmalt, Carapils, Sourmalt</li>
          <li>Nelson Savuin</li>
          <li>WYEAST 1272 American Ale II</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#10 Glutenfree Amarillo/Mosaic June 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>Planned was a 4.6% glutenfree beer without any malts. Unfortunately it was way to bitter and had no touch of a beer - undrickable
        <ul>
          <li>Amaranth, Chateau Buckwheat, Corn Flakes, Rice Flakes</li>
          <li>Amarillo, Mosaic</li>
          <li>WYEAST 1272 American Ale II</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#9 Classic Pale Ale Gigimaumau June 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>5.0% Pale Ale made for the birthday of Brewmaster Dans daughter. She's also the name-giver
        <ul>
          <li>Pale Ale, Pilsener, Maris Otter, Sourmalt</li>
          <li>Citra</li>
          <li>White Labs WLP001 California Ale</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#8 Verdant Lightbulp-Clone (Litebulb) May 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>5.0% Pale Ale clone, original by the famous Cornish brewery Verdant.
        <ul>
          <li>Pale Ale, Wiener, Caraamber, Pilsener</li>
          <li>Magnum US, Simcoe, Centennial</li>
          <li>SafAle US-04 / US-05</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#7 Summit-Glacier American Pale Ale April 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>4.8% American Pale Ale with Summit and Glacier Hops
        <ul>
          <li>Pale Ale, Caraamber, Caramünch III</li>
          <li>Summit, Glacier</li>
          <li>WYEAST 1272 American Ale II</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#6 Melon Session March 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>3.5% Session IPA with Huell Melon for a fruity touch
        <ul>
          <li>Pale Ale, Caramünch II, Sourmalt</li>
          <li>Centennial, Amarillo, Citra, Huell Melon</li>
          <li>WYEAST 1272 American Ale II</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#5 BB Mild Ale March 2020</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>4.1% ale - maltier and golden in color
        <ul>
          <li>Pale Ale, Caramünch II, Sourmalt</li>
          <li>Centennial, Amarillo, Citra, Huell Melon</li>
          <li>WYEAST 1098 British Ale</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#4 Guinea Pat Stout Dec 2019</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>Light Stout with 3.5%
        <ul>
          <li>Wiener, Pale Ale, Caramünch III, Roast Barley, Barley-Flakes</li>
          <li>Fuggle, Golding East Kent</li>
          <li>WYEAST 1084 Irish Ale</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#3 BB American Pale Ale Dec 2019</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>First beer without any kit
        <ul>
          <li>Pale Ale, Caraamber, Caramünch III</li>
          <li>Centennial, Citra, Simcoe</li>
          <li>WYEAST 1056 American Ale</li>
        </ul></mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#2 Simco IPA Oct 2019</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>Brew of a IPA with Simcoe hops (brewkit)</mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
      <mgl-timeline-entry-header>#1 Kölsch-Style Oct 2019</mgl-timeline-entry-header>
      <mgl-timeline-entry-content>First time brewed a Kölsch-Style beer (brewkit)</mgl-timeline-entry-content>
      <mgl-timeline-entry-dot size="20" class="accent"></mgl-timeline-entry-dot>
  </mgl-timeline-entry>
</mgl-timeline>
</div>

<router-outlet></router-outlet>